<template>
  <div class="text">
    <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  props: ['strings', 'lang'],
  name: "Error",
  data() {
    return {
      msg : 'დაფიქსირდა შეცდომა'
    }
  },
}
</script>

<style scoped>

</style>